import { useEffect, useRef, useState } from "react";
import { Pause, Play } from "../../../lib/icons";
import {
  ElementWrapper,
  AudioIcon,
  AudioWrapper,
  ProgressBar,
  ProgressBarContainer,
  InfoWrapper,
  Title,
  Subtitle,
} from "./styles";
import { getStrapiMediaUrl } from "../../../utils";

const formatToMinuteAndSeconds = (time) => {
  if (isNaN(time)) return "-:--";
  if (time === 0) return "0:00";

  const minutes = Math.floor(time / 60);
  const seconds = Math.ceil(time - minutes * 60);
  if (seconds < 10) return `${minutes}:0${seconds}`;
  return `${minutes}:${seconds}`;
};

const useAudioData = (src) => {
  const audioElement = useRef(null);
  const progressBarElement = useRef(null);
  const [duration, setDuration] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    progressBarElement.current.style.width = "0%";
    audioElement.current.onloadeddata = () => {
      setDuration(audioElement.current.duration);
    };
    setIsPlaying(false);
  }, [src]);

  const play = () => {
    document.querySelectorAll("audio").forEach((audio) => {
      audio.pause();
    });

    audioElement.current.play();
    setIsPlaying(true);
  };

  const pause = () => {
    audioElement.current.pause();
    setIsPlaying(false);
  };

  const updateTime = () => {
    progressBarElement.current.style.width = `${
      (audioElement.current.currentTime / duration) * 100
    }%`;
    setCurrentTime(audioElement.current.currentTime);
  };

  const setPercentage = (percentage) => {
    audioElement.current.currentTime = percentage * duration;
  };

  return {
    audio: {
      elementRef: audioElement,
      duration: duration,
      currentTime: currentTime,
      isPlaying: isPlaying,
      play: play,
      pause: pause,
      updateTime: updateTime,
      setPercentage: setPercentage,
    },
    progressBar: { elementRef: progressBarElement },
  };
};

export const AudioItem = ({ media, title, subtitle, ...props }) => {
  const src = getStrapiMediaUrl(media.url);
  const { audio, progressBar } = useAudioData(src);

  return (
    <ElementWrapper>
      <audio
        src={src}
        {...props}
        ref={audio.elementRef}
        onPause={audio.pause}
        onTimeUpdate={audio.updateTime}
        onEnded={audio.pause}
      />
      {title && <Title>{title}</Title>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      <AudioWrapper>
        <AudioIcon
          onClick={() => {
            audio.isPlaying ? audio.pause() : audio.play();
          }}
        >
          {audio.isPlaying ? Pause : Play}
        </AudioIcon>
        <ProgressBarContainer
          onClick={(e) => {
            if (audio.duration) {
              audio.setPercentage(
                e.nativeEvent.offsetX / e.currentTarget.clientWidth
              );
            }
          }}
        >
          <ProgressBar
            $isPlaying={audio.isPlaying}
            ref={progressBar.elementRef}
          />
        </ProgressBarContainer>
      </AudioWrapper>
      <InfoWrapper>
        <p>{formatToMinuteAndSeconds(audio.currentTime)}</p>
        <p>{formatToMinuteAndSeconds(audio.duration)}</p>
      </InfoWrapper>
    </ElementWrapper>
  );
};

export default AudioItem;
