import tw from "tailwind-styled-components";

export const ElementWrapper = tw.div`
  w-full
`;

export const AudioWrapper = tw.div`
  flex
  flex-row
  items-center
  gap-6
  pl-2
  h-8
`;

export const AudioIcon = tw.div`
  w-5
  cursor-pointer
`;

export const ProgressBarContainer = tw.div`
  w-full
  h-1
  bg-gray-200
  rounded-full
  cursor-pointer
`;

export const ProgressBar = tw.div`
  h-1
  rounded-full
  ${({ $isPlaying }) => ($isPlaying ? "bg-cta-800" : "bg-primary-800")}
`;

export const InfoWrapper = tw.div`
  flex
  justify-between
  text-xs
  pl-12
`;

export const Title = tw.p`
  text-lg
  pl-12

  desktop:hidden
`;

export const Subtitle = tw.p`
  text-base
  pl-12
  text-gray-700

  desktop:hidden
`;
